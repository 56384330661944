import { useEffect, useState } from "react";
import styles from "./Select.module.scss";
import selectArrow from "../../assets/icons/select_arrow.svg";

function Select(props) {
  const { items, placeholder } = props;
  const [selectedItem, setSelectedItem] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [hasBrownBackground, setBrownBackground] = useState(false);

  useEffect(() => {
    if (placeholder) {
      setSelectedItem(placeholder);
      setBrownBackground(placeholder.brownOnSelect);
    } else {
      if (items) {
        setSelectedItem(items[0]);
        setBrownBackground(items[0].brownOnSelect);
      } else
        setSelectedItem({
          text: "No data available",
        });
    }
  }, []); //eslint-disable-line

  return (
    <div
      className={`${styles.select_container} ${
        hasBrownBackground && styles.brown_bg
      } ${isOpen && styles.open}`}
      onBlur={() => {
        setIsOpen(!isOpen);
      }}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <div className={styles.selected_element}>
        {selectedItem?.icon && (
          <div className={styles.icon}>
            <img src={selectedItem.icon} alt="select-icon" />
          </div>
        )}
        <div className={styles.text}>{selectedItem.text}</div>
        {selectedItem?.secondaryText && (
          <div className={styles.secondary_text}>
            {selectedItem.secondaryText}
          </div>
        )}
        <div className={styles.arrow}>
          <img src={selectArrow} alt="select-arrow" />
        </div>
      </div>
      {isOpen && (
        <div className={styles.open_container}>
          <div className={styles.element_container}>
            {items?.map((item, index) => {
              if (item.hideThisElement) return null;
              return (
                <div
                  className={styles.element}
                  key={index}
                  onClick={() => {
                    if (!item.dontChangeOnClick) {
                      setSelectedItem(item);
                      setBrownBackground(item.brownOnSelect === true);
                    }
                    setIsOpen(!isOpen);
                    if (item.onSelectCallback) item.onSelectCallback();
                  }}
                >
                  {item?.icon && (
                    <div className={styles.icon}>
                      <img src={item.icon} alt="select-icon" />
                    </div>
                  )}
                  <div
                    className={`${styles.text} ${
                      item.fontColor === "orange"
                        ? styles.orange
                        : item.fontColor === "lightBrown"
                        ? styles.lightBrown
                        : ""
                    }`}
                  >
                    {item.text}
                  </div>
                  {item?.secondaryText && (
                    <div className={styles.secondary_text}>
                      {item.secondaryText}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default Select;
