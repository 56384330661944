import styles from "./Dropzone.module.scss";
import plusLB from "../../assets/icons/plus_light_brown.svg";

function Dropzone() {
  return (
    <div className={styles.dropzone_container}>
      <div className={styles.icon}>
        <img src={plusLB} alt="plus" />
      </div>
      <div className={styles.text}>Add New</div>
    </div>
  );
}

export default Dropzone;
