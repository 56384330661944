import styles from "./MyBusiness.module.scss";
import Layout from "../../components/layout/Layout";
import browse from "../../assets/icons/browse.svg";
import edit from "../../assets/icons/edit.svg";
import coffeeFlowers from "../../assets/svgs/coffee_flowers.svg";
import SimpleCard from "../../components/simpleCard/SimpleCard";
import { useState } from "react";
import LineGraph from "../../components/lineGraph/LineGraph";
import RatingsTable from "../../components/ratingsTable/RatingsTable";

function MyBusiness() {
  const [productHighlightsHeight, setProductHighlightsHeight] = useState(null);

  return (
    <Layout
      pageTitle="My Business"
      subTitle={
        <div className={styles.dashboard_select}>
          <img src={browse} alt="arrow" />
          <span>Preview</span>
        </div>
      }
    >
      <div className={styles.body}>
        <div
          className={`${styles.column} ${styles.business_info}`}
          style={{ height: productHighlightsHeight - 1 + "px" }}
        >
          <div className={styles.header}>
            <div className={styles.title}>Starbucks DO</div>
            <div className={styles.edit}>
              <img src={edit} alt="edit" />
              <p>Edit</p>
            </div>
          </div>
          <div className={styles.business_title}>Branch Acrópolis</div>
          <div className={styles.image}>
            <img src={coffeeFlowers} alt="icon" />
          </div>
          <div className={styles.description}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod
          </div>
          <div className={styles.hours}>
            Mon- Sat 7:00 am - 8:00pm
            <p>
              Sun <span className={styles.closed}>Closed</span>
            </p>
          </div>
          <div className={styles.address}>
            Robert Robertson, 1234 NW Bobcat Lane, St. Robert, MO 65584-5678.
          </div>
        </div>
        <div
          className={styles.column}
          style={{ height: productHighlightsHeight + "px" }}
        >
          <div className={styles.line_graph_card}>
            <SimpleCard
              title={"My competitors"}
              height={productHighlightsHeight / 2 - 14}
              className={styles.card}
              subtitle={"Check-ins"}
              setHeightCallback={(height) => {
                setProductHighlightsHeight(height);
              }}
              body={<LineGraph />}
            />
          </div>
          <div className={styles.line_graph_card}>
            <SimpleCard
              title={"My competitors"}
              height={productHighlightsHeight / 2 - 14}
              setHeightCallback={(height) => {
                setProductHighlightsHeight(height);
              }}
              className={styles.card}
              subtitle={"Check-ins"}
              body={<LineGraph />}
            />
          </div>
        </div>
        <div className={styles.table_column}>
          <SimpleCard
            title={"Product"}
            className={styles.table_card}
            height={productHighlightsHeight - 1}
            setHeightCallback={(height) => {
              setProductHighlightsHeight(height);
            }}
            subTitle={
              <div className={styles.product_highlight_subtitle}>Ratings</div>
            }
            body={
              <RatingsTable
                data={[
                  {
                    id: 0,
                    productName: "Vanilla Capuccino",
                    rates: 22,
                    comments: 10,
                    rating: 5,
                  },
                  {
                    id: 1,
                    productName: "Mocha Coffee",
                    rates: 14,
                    rating: 5,
                  },
                  {
                    id: 2,
                    productName: "Cafe con leche",
                    rates: 3,
                    rating: 5,
                  },
                  {
                    id: 3,
                    productName: "Vanilla Capuccino",
                    rates: 53,
                    rating: 4,
                  },
                  {
                    id: 4,
                    productName: "Vanilla Capuccino",
                    rates: 5,
                    comments: 3,
                    rating: 4,
                  },
                  {
                    id: 5,
                    productName: "Mocha Coffee",
                    rates: 11,
                    comments: 8,
                    rating: 3,
                  },
                  {
                    id: 6,
                    productName: "Espresso",
                    rates: 120,
                    comments: 35,
                    rating: 4,
                  },
                  {
                    id: 7,
                    productName: "Cappuccino",
                    rates: 68,
                    comments: 21,
                    rating: 4,
                  },
                  {
                    id: 8,
                    productName: "Latte Macchiato",
                    rates: 43,
                    comments: 10,
                    rating: 3,
                  },
                  {
                    id: 9,
                    productName: "Americano",
                    rates: 78,
                    comments: 15,
                    rating: 3,
                  },
                  {
                    id: 10,
                    productName: "Flat White",
                    rates: 28,
                    rating: 5,
                  },
                  {
                    id: 11,
                    productName: "Mocha Latte",
                    rates: 19,
                    rating: 4,
                  },
                  {
                    id: 12,
                    productName: "Iced Coffee",
                    rates: 57,
                    comments: 14,
                    rating: 3,
                  },
                  {
                    id: 13,
                    productName: "Caramel Macchiato",
                    rates: 38,
                    comments: 9,
                    rating: 4,
                  },
                  {
                    id: 14,
                    productName: "Cinnamon Latte",
                    rates: 12,
                    rating: 5,
                  },
                  {
                    id: 15,
                    productName: "Turkish Coffee",
                    rates: 93,
                    comments: 25,
                    rating: 3,
                  },
                ]}
              />
            }
          />
        </div>
      </div>
    </Layout>
  );
}

export default MyBusiness;
