import styles from "./Profile.module.scss";
import Layout from "../../components/layout/Layout";
import browse from "../../assets/icons/browse.svg";
import Input from "../../components/input/Input";
import Dropzone from "../../components/dropzone/Dropzone";
import Button from "../../components/button/Button";

function Profile() {
  const onUploadCallback = () => {};
  return (
    <Layout
      pageTitle="My Business | Profile"
      subTitle={
        <div className={styles.dashboard_select}>
          <img src={browse} alt="arrow" />
          <span>Preview</span>
        </div>
      }
    >
      <div className={styles.body}>
        <div className={styles.add_new_branch_container}>
          <div className={`${styles.description} ${styles.card}`}>
            <div className={styles.title}>Description</div>
            <div className={styles.inputs}>
              <div className={styles.input_container}>
                <Input
                  type="text"
                  id="companyName"
                  label="* Company name"
                  labelOutsideInput
                  inputClassName={styles.input}
                />
              </div>
              <div className={styles.input_container}>
                <Input
                  type="textarea"
                  id="description"
                  label="* Description"
                  labelOutsideInput
                  inputClassName={styles.input}
                />
              </div>
              <div className={styles.input_container}>
                <Input
                  type="time"
                  id="schedule"
                  label="* Schedule"
                  labelOutsideInput
                  inputClassName={styles.input}
                />
              </div>
            </div>
          </div>
          <div className={`${styles.address}  ${styles.card}`}>
            <div className={styles.title}>Address</div>
            <div className={styles.inputs}>
              <div className={styles.input_container}>
                <Input
                  type="text"
                  id="address1"
                  label="* Address Line 1"
                  labelOutsideInput
                  inputClassName={styles.input}
                />
              </div>
              <div className={styles.input_container}>
                <Input
                  type="text"
                  id="address2"
                  label="* Address Line 2"
                  labelOutsideInput
                  inputClassName={styles.input}
                />
              </div>
              <div className={styles.half_input_container}>
                <div className={styles.half}>
                  <Input
                    type="text"
                    id="city"
                    label="* City"
                    labelOutsideInput
                    inputClassName={styles.input}
                  />
                </div>
                <div className={styles.half}>
                  <Input
                    type="text"
                    id="state"
                    label="* State"
                    labelOutsideInput
                    inputClassName={styles.input}
                  />
                </div>
              </div>
              <div className={styles.half_input_container}>
                <div className={styles.half}>
                  <Input
                    type="text"
                    id="country"
                    label="* Country"
                    labelOutsideInput
                    inputClassName={styles.input}
                  />
                </div>
                <div className={styles.half}>
                  <Input
                    type="number"
                    id="zip"
                    label="* ZIP Code"
                    labelOutsideInput
                    inputClassName={styles.input}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.profile_picture}  ${styles.card}`}>
            <div className={styles.title}>Profile Picture</div>
            <div className={styles.inputs}>
              <div className={styles.input_container}>
                <Input
                  type="text"
                  id="akjcbjk"
                  label="* akjcbjk"
                  labelOutsideInput
                  inputClassName={styles.input}
                />
              </div>
              <div className={styles.input_container}>
                <span className={styles.label}>* Profile picture</span>
                <div className={styles.dropzone_container}>
                  <Dropzone onUploadCallback={onUploadCallback} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.button}>
          <Button type={1} text={"Save changes"} />
        </div>
      </div>
    </Layout>
  );
}

export default Profile;
