import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import Login from "../pages/login/Login";
import MyCustomers from "../pages/myCustomers/MyCustomers";
import ProductHighlights from "../pages/productHighlights/ProductHighlights";
import { CUPPD_INSIGHTS, DASHBOARD, DEFAULT, LOGIN, MENU, MY_BUSINESS, MY_CUSTOMERS, PRODUCT_HIGHLIGHTS, PROFILE } from "./Routes";
import CuppdInsights from "../pages/cuppdInsights/CuppdInsights";
import MyBusiness from "../pages/myBusiness/MyBusiness";
import Menu from "../pages/menu/Menu";
import Profile from "../pages/profile/Profile";

function Router() {
  return (
    <Routes>
      <Route path={DEFAULT} element={<Login />} />
      <Route path={LOGIN} element={<Login />} />
      <Route path={DASHBOARD} element={<Dashboard />} exact />
      <Route path={PRODUCT_HIGHLIGHTS} element={<ProductHighlights />} />
      <Route path={MY_CUSTOMERS} element={<MyCustomers />} />
      <Route path={CUPPD_INSIGHTS} element={<CuppdInsights />} />
      <Route path={MY_BUSINESS} element={<MyBusiness />} />
      <Route path={MENU} element={<Menu />} />
      <Route path={PROFILE} element={<Profile />} />
      <Route path="*" element={<div>404</div>} />
    </Routes>
  );
}

export default Router;
