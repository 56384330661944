import styles from "./CuppdInsights.module.scss";
import arrow from "../../assets/icons/select_arrow_orange.svg";
import Layout from "../../components/layout/Layout";
import Logo from "../../assets/icons/cup_logo.svg";
import swirl from "../../assets/svgs/swirl.svg";
import locationPin from "../../assets/icons/location_pin.svg";
import personTurquoise from "../../assets/icons/user_turquoise.svg";
import coffeeFlowers from "../../assets/svgs/coffee_flowers.svg";
import starLightBrown from "../../assets/icons/star_light_brown.svg";
import virginia from "../../assets/svgs/virginia.svg";
import nyc from "../../assets/svgs/nyc.svg";
import la from "../../assets/svgs/la.svg";
import usa from "../../assets/svgs/usa.svg";
import instagram from "../../assets/icons/instagram.svg";
import { useEffect, useRef, useState } from "react";

function CuppdInsights() {
  const circleRef = useRef(null);
  const circle2Ref = useRef(null);
  const [pieChartPercentage] = useState(48);
  const [pieChart2Percentage] = useState(52);

  useEffect(() => {
    const circumference = 2 * Math.PI * circleRef.current.r.baseVal.value;
    const progressOffset =
      circumference - (pieChartPercentage / 100) * circumference;
    circleRef.current.style.strokeDashoffset = progressOffset;
  }, [pieChartPercentage]);

  useEffect(() => {
    const circumference = 2 * Math.PI * circle2Ref.current.r.baseVal.value;
    const progressOffset =
      circumference - (pieChart2Percentage / 100) * circumference;
    circle2Ref.current.style.strokeDashoffset = progressOffset;
  }, [pieChart2Percentage]);

  return (
    <Layout
      pageTitle="Cupp'd Insights"
      subTitle={
        <div className={styles.dashboard_select}>
          This week
          <img src={arrow} alt="arrow" />
        </div>
      }
    >
      <div className={styles.body}>
        <div className={styles.column}>
          <div
            className={`${styles.card} ${styles.small_card} ${styles.star_product}`}
          >
            <div className={styles.star_product_container}>
              <div className={styles.title}>Star Product</div>
              <div className={styles.subtitle}>Cinnamon Capuccino</div>
              <div className={styles.amount}>14,389</div>
              <div className={styles.amount_subtitle}>Check-ins</div>
              <div className={styles.icons}>
                <img className={styles.star} src={starLightBrown} alt="star" />
                <img className={styles.cup} src={Logo} alt="cup" />
              </div>
            </div>
          </div>
          <div
            className={`${styles.card} ${styles.large_card} ${styles.other_topic}`}
          >
            <div className={styles.title}>Other topic</div>
            <div className={styles.subtitle}>Another noun that is longer</div>
            <div className={styles.amount}>19-25</div>
            <div className={styles.amount_subtitle}>Variable</div>
            <img className={styles.swirl} src={swirl} alt={"swirl"} />
            <img className={styles.person} src={personTurquoise} alt="icon" />
            <div className={styles.paragraph}>
              This product is the one that works best in your business and has
              20% more check-ins than the average in your area. This product is
              the one that works best in your store and has 20%
            </div>
          </div>
        </div>
        <div className={`${styles.column} ${styles.three_card_column}`}>
          <div
            className={`${styles.card} ${styles.small_card} ${styles.most_shared_picture}`}
          >
            <div
              className={`${styles.title} ${styles.large} ${styles.bottom_right}`}
            >
              Most shared picture
            </div>
            <img src={coffeeFlowers} alt="bg-coffee" />
          </div>
          <div
            className={`${styles.card} ${styles.small_card} ${styles.most_used_tags}`}
          >
            <div className={styles.title}>Most used tags</div>
            <div className={`${styles.popup} ${styles.popup_1}`}>Mom & Pop</div>
            <div className={`${styles.popup} ${styles.popup_2}`}>Starbucks</div>
            <div className={`${styles.popup} ${styles.popup_3}`}>Pumpkin</div>
            <div className={`${styles.popup} ${styles.popup_4}`}>Mom & Pop</div>
            <div className={`${styles.popup} ${styles.popup_5}`}>Capuccino</div>
            <div className={`${styles.popup} ${styles.popup_6}`}>
              Almond milk
            </div>
            <div className={`${styles.popup} ${styles.popup_7}`}>
              Vegan coffee
            </div>
            <div className={`${styles.popup} ${styles.popup_8}`}>Biscuits</div>
          </div>
          <div
            className={`${styles.card} ${styles.small_card} ${styles.other_topic_pie}`}
          >
            <div className={`${styles.title} ${styles.reverse}`}>
              Other topic
            </div>
            <div className={styles.amounts}>
              <div className={styles.amount}>14,389</div>
              <div className={styles.amount_subtitle}>Variable</div>
            </div>
            <div className={styles.pie_chart}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115 115">
                <circle
                  cx="57.5"
                  cy="57.5"
                  r="52.5"
                  fill="none"
                  stroke="#746054"
                  strokeWidth="10"
                />
                <circle
                  ref={circleRef}
                  cx="57.5"
                  cy="57.5"
                  r="52.5"
                  fill="none"
                  stroke="#F7F6F3"
                  strokeWidth="10"
                  strokeDasharray="328.65"
                  strokeDashoffset="0"
                  style={{ transition: "stroke-dashoffset 0.5s ease" }}
                />
              </svg>
              <div className={styles.value}>{pieChartPercentage}%</div>
            </div>
          </div>
        </div>
        <div className={styles.column}>
          <div
            className={`${styles.card} ${styles.large_card} ${styles.top_three_places}`}
          >
            <div className={`${styles.title} ${styles.large}`}>
              Top 3 places with most Check-ins
            </div>
            <img className={styles.pin} src={locationPin} alt="icon" />
            <div className={styles.location_icons}>
              <div className={styles.row}>
                <div className={styles.icon}>
                  <img className={styles.city_icon} src={virginia} alt="city" />
                  <div className={styles.number}>1</div>
                </div>
                <div className={styles.city_info}>
                  <div className={styles.city_title}>Virginia</div>
                  <div className={styles.info}>
                    <b>4,389</b> check-ins
                  </div>
                  <div className={styles.info}>
                    <b>23,389</b> clicks
                  </div>
                  <div className={styles.info}>
                    <b>9,000</b> shares
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.icon}>
                  <img className={styles.city_icon} src={nyc} alt="city" />
                  <div className={styles.number}>2</div>
                </div>
                <div className={styles.city_info}>
                  <div className={styles.city_title}>New York City</div>
                  <div className={styles.info}>
                    <b>4,389</b> check-ins
                  </div>
                  <div className={styles.info}>
                    <b>23,389</b> clicks
                  </div>
                  <div className={styles.info}>
                    <b>9,000</b> shares
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.icon}>
                  <img className={styles.city_icon} src={la} alt="city" />
                  <div className={styles.number}>3</div>
                </div>
                <div className={styles.city_info}>
                  <div className={styles.city_title}>Los Angeles</div>
                  <div className={styles.info}>
                    <b>4,389</b> check-ins
                  </div>
                  <div className={styles.info}>
                    <b>23,389</b> clicks
                  </div>
                  <div className={styles.info}>
                    <b>9,000</b> shares
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.paragraph}>
              This place is the one that works best in your business and has 20%
              more check-ins than the average in your area.
            </div>
          </div>
          <div
            className={`${styles.card} ${styles.small_card} ${styles.winner_location}`}
          >
            <div className={styles.title}>Winner Location</div>
            <img src={usa} alt="usa" />
            <div className={styles.amount}>4,389</div>
            <div className={styles.amount_subtitle}>Variable</div>
            <div className={styles.paragraph}>
              This place is the one that works best in your business and has 20%
              more check-ins than the average in your area.{" "}
            </div>
          </div>
        </div>
        <div className={`${styles.column}`}>
          <div
            className={`${styles.card} ${styles.small_card} ${styles.other_topic_variable}`}
          >
            <div className={`${styles.title}`}>Other topic</div>
            <div className={styles.amounts}>
              <div className={styles.amount}>14,389</div>
              <div className={styles.amount_subtitle}>Variable</div>
            </div>
            <div className={styles.pie_chart}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115 115">
                <circle
                  cx="57.5"
                  cy="57.5"
                  r="52.5"
                  fill="none"
                  stroke="#09665e"
                  strokeWidth="10"
                />
                <circle
                  ref={circle2Ref}
                  cx="57.5"
                  cy="57.5"
                  r="52.5"
                  fill="none"
                  stroke="#F7F6F3"
                  strokeWidth="10"
                  strokeDasharray="328.65"
                  strokeDashoffset="0"
                  style={{ transition: "stroke-dashoffset 0.5s ease" }}
                />
              </svg>
              <div className={styles.value}>{pieChart2Percentage}%</div>
            </div>
          </div>
          <div
            className={`${styles.card} ${styles.large_card} ${styles.other_topic_ig}`}
          >
            <div className={styles.title}>Other topic</div>
            <div className={styles.subtitle}>Another noun that is longer</div>
            <img className={styles.swirl} src={swirl} alt={"swirl"} />
            <div className={styles.orange_box}>
              <img className={styles.instagram} src={instagram} alt="icon" />
            </div>
            <div className={styles.paragraph}>
              This product is the one that works best in your business and has
              20% more check-ins than the average in your area. This product is
              the one that works best in your store and has 20%
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CuppdInsights;
