import { useEffect, useState } from "react";
import bell from "../../assets/icons/bell.svg";
import starIcon from "../../assets/icons/star_brown.svg";
import styles from "./Notifications.module.scss";
import moment from "moment";

function Notifications(props) {
  const { amountOfUnreadNotifications } = props;
  const [isOpen, setIsOpen] = useState(false);

  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const now = new Date();
    setNotifications([
      {
        id: 1,
        message: "You upgraded to Platinum Plus",
        date: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
      },
      {
        id: 2,
        message: "You upgraded to Platinum Plus",
        date: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
      },
      {
        id: 3,
        message: "You upgraded to Platinum Plus",
        date: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14),
      },
      {
        id: 4,
        message: "You upgraded to Platinum Plus",
        date: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 21),
      },
    ]);
  }, []);

  function getNotificationElements() {
    if (!notifications || notifications.length === 0) {
      return (
        <div className={styles.notification}>
          <div className={styles.date_title}>No pending notifications.</div>
        </div>
      );
    } else {
      const groups = notifications.reduce((acc, item) => {
        const yearWeek = `${moment(item.date).year()}-${moment(
          item.date
        ).week()}`;
        if (!acc[yearWeek]) {
          acc[yearWeek] = [];
        }
        acc[yearWeek].push(item);
        return acc;
      }, {});

      const groupNames = Object.keys(groups);
      let sortedNotifications = [];

      for (let i = 0; i < groupNames.length; i++) {
        const propName = groupNames[i];
        const propArray = groups[propName];
        const weeksAgo = groupNames.length - i;

        sortedNotifications.push({
          weeksAgo: weeksAgo,
          notifications: propArray,
        });
      }

      sortedNotifications = sortedNotifications.reverse();

      return sortedNotifications.map((notification, index) => (
        <div className={styles.notification} key={index}>
          <div className={styles.date_title}>
            {notification.weeksAgo < 1
              ? "This week"
              : notification.weeksAgo === 1
              ? "Last week"
              : notification.weeksAgo + " weeks ago"}
          </div>
          {notification.notifications.map((n, i) => (
            <div className={styles.notification} key={i}>
              <div className={styles.star_icon}>
                <img src={starIcon} alt="star" />
              </div>
              <div className={styles.message}>{n.message}</div>
            </div>
          ))}
        </div>
      ));
    }
  }

  return (
    <div
      className={styles.notifications}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <img src={bell} alt="notification" />
      {amountOfUnreadNotifications && amountOfUnreadNotifications > 0 ? (
        <span>{amountOfUnreadNotifications}</span>
      ) : null}
      {isOpen && (
        <div className={styles.notification_menu_container}>
          <div className={styles.notification_menu}>
            {getNotificationElements()}
          </div>
        </div>
      )}
    </div>
  );
}

export default Notifications;
