import styles from "./TripleProductHighlight.module.scss";

function TripleProductHighlight(props) {
  const { data } = props;

  let firstItem = data[0] ?? {};
  let secondItem = data[1] ?? {};
  let thirdItem = data[2] ?? {};

  return (
    <div className={styles.columns_container}>
      <div className={styles.column}>
        <div className={styles.number}>1</div>
        <div className={styles.icon}>
          <img src={firstItem?.icon} alt="icon" />
        </div>
        <div className={styles.title}>{firstItem?.title}</div>
        <div className={styles.check_ins}>{firstItem?.checkIns} check-ins</div>
        <div className={styles.clicks}>{firstItem?.clicks} clicks</div>
        <div className={styles.shares}>{firstItem?.shares} shares</div>
      </div>
      <div className={styles.column}>
        <div className={styles.number}>2</div>
        <div className={styles.icon}>
          <img src={secondItem?.icon} alt="icon" />
        </div>
        <div className={styles.title}>{secondItem?.title}</div>
        <div className={styles.check_ins}>{secondItem?.checkIns} check-ins</div>
        <div className={styles.clicks}>{secondItem?.clicks} clicks</div>
        <div className={styles.shares}>{secondItem?.shares} shares</div>
      </div>
      <div className={styles.column}>
        <div className={styles.number}>3</div>
        <div className={styles.icon}>
          <img src={thirdItem?.icon} alt="icon" />
        </div>
        <div className={styles.title}>{thirdItem?.title}</div>
        <div className={styles.check_ins}>{thirdItem?.checkIns} check-ins</div>
        <div className={styles.clicks}>{thirdItem?.clicks} clicks</div>
        <div className={styles.shares}>{thirdItem?.shares} shares</div>
      </div>
    </div>
  );
}

export default TripleProductHighlight;
