import styles from "./ProductHighlights.module.scss";
import Layout from "../../components/layout/Layout";
import arrow from "../../assets/icons/select_arrow_orange.svg";
import coffeeDragon from "../../assets/svgs/coffee_dragon.svg";
import mochaCoffee from "../../assets/svgs/mocha_coffee.svg";
import coffeeFlowers from "../../assets/svgs/coffee_flowers.svg";
import SimpleCard from "../../components/simpleCard/SimpleCard";
import BarGraph from "../../components/barGraph/BarGraph";
import { useState } from "react";
import TripleProductHighlight from "../../components/tripleProductHighlight/TripleProductHighlight";
import RatingsTable from "../../components/ratingsTable/RatingsTable";

function ProductHighlights() {
  const [checkInsGraphData] = useState([
    { id: 1, value: 90, label: "Mon" },
    { id: 2, value: 170, label: "Tue" },
    {
      id: 3,
      value: 250,
      label: "Wed",
      name: "Cinnamon Capuccino",
      unit: "check-ins",
      icon: coffeeDragon,
    },
    { id: 4, value: 60, label: "Thu" },
    { id: 5, value: 170, label: "Fri" },
    { id: 6, value: 190, label: "Sat" },
    { id: 7, value: 65, label: "Sun" },
  ]);

  const [sharesGraphData1] = useState([
    { id: 1, value: 90, label: "Mon" },
    { id: 2, value: 170, label: "Tue" },
    { id: 3, value: 250, label: "Wed" },
    { id: 4, value: 60, label: "Thu" },
    { id: 5, value: 170, label: "Fri" },
    {
      id: 6,
      value: 349,
      label: "Sat",
      name: "Mocha Coffee",
      unit: "shares",
      icon: coffeeDragon,
    },
    { id: 7, value: 65, label: "Sun" },
  ]);

  const [sharesGraphData2] = useState([
    { id: 1, value: 90, label: "Mon" },
    { id: 2, value: 170, label: "Tue" },
    {
      id: 3,
      value: 250,
      label: "Wed",
      name: "Vanilla Capuccino",
      unit: "shares",
      icon: coffeeDragon,
    },
    { id: 4, value: 60, label: "Thu" },
    { id: 5, value: 170, label: "Fri" },
    { id: 6, value: 190, label: "Sat" },
    { id: 7, value: 65, label: "Sun" },
  ]);

  const [topPerformingProducts] = useState([
    {
      icon: coffeeDragon,
      title: "Cinnamon Capuccino",
      checkIns: 230,
      clicks: 230,
      shares: 40
    },
    {
      icon: mochaCoffee,
      title: "Mocha Coffee",
      checkIns: 120,
      clicks: 90,
      shares: 12
    },
    {
      icon: coffeeFlowers,
      title: "Cafe con leche",
      checkIns: 30,
      clicks: 300,
      shares: 13
    }
  ]);

  const [productHighlightsHeight, setProductHighlightsHeight] = useState(null);

  function DashboardSubtitle() {
    return (
      <>
        <span className={styles.page_subtitle_2}>Product Highlights</span>
        <div className={styles.dashboard_select}>
          This week
          <img src={arrow} alt="arrow" />
        </div>
      </>
    );
  }

  return (
    <Layout pageTitle="Dashboard | " subTitle={<DashboardSubtitle />}>
      <div className={styles.body}>
        <div className={styles.left}>
          <div className={styles.chart}>
            <SimpleCard
              title={"Product"}
              setHeightCallback={(height) => {
                setProductHighlightsHeight(height);
              }}
              className={styles.card}
              subtitle={
                <div className={styles.product_highlight_subtitle}>
                  Check-ins
                </div>
              }
              height={productHighlightsHeight}
              body={
                <BarGraph
                  data={checkInsGraphData}
                  highlight
                  popup
                  className={styles.card}
                />
              }
            />
          </div>
          <div className={styles.chart}>
            <SimpleCard
              title={"Product"}
              setHeightCallback={(height) => {
                setProductHighlightsHeight(height);
              }}
              className={styles.card}
              subtitle={
                <div className={styles.product_highlight_subtitle}>Shares</div>
              }
              height={productHighlightsHeight}
              body={
                <BarGraph
                  lightBrown
                  data={sharesGraphData1}
                  highlight
                  popup
                  className={styles.card}
                />
              }
            />
          </div>
          <div className={styles.chart}>
            <SimpleCard
              title={"Product"}
              setHeightCallback={(height) => {
                setProductHighlightsHeight(height);
              }}
              className={styles.card}
              subtitle={
                <div className={styles.product_highlight_subtitle}>Shares</div>
              }
              height={productHighlightsHeight}
              body={
                <BarGraph
                  data={sharesGraphData2}
                  lightBrown
                  highlight
                  popup
                  className={styles.card}
                />
              }
            />
          </div>
          <div className={styles.chart}>
            <SimpleCard
              title={"Top performing products"}
              setHeightCallback={(height) => {
                setProductHighlightsHeight(height);
              }}
              className={styles.card}
              height={productHighlightsHeight}
              body={
                <TripleProductHighlight data={topPerformingProducts} />
              }
            />
          </div>
        </div>
        <div className={styles.right}>
            <SimpleCard 
              title={"Product"}
              className={styles.card_right}
              height={629}
              subTitle={
                <div className={styles.product_highlight_subtitle}>Ratings</div>
              }
              body={
                <RatingsTable
                  data={[
                    {
                      id:0,
                      productName: "Vanilla Capuccino",
                      rates: 22,
                      comments: 10,
                      rating: 5
                    },
                    {
                      id:1,
                      productName: "Mocha Coffee",
                      rates: 14,
                      rating: 5
                    },
                    {
                      id:2,
                      productName: "Cafe con leche",
                      rates: 3,
                      rating: 5
                    },
                    {
                      id:3,
                      productName: "Vanilla Capuccino",
                      rates: 53,
                      rating: 4
                    },
                    {
                      id:4,
                      productName: "Vanilla Capuccino",
                      rates: 5,
                      comments: 3,
                      rating: 4
                    },
                    {
                      id:5,
                      productName: "Mocha Coffee",
                      rates: 11,
                      comments: 8,
                      rating: 3
                    },
                    {
                      id: 6,
                      productName: "Espresso",
                      rates: 120,
                      comments: 35,
                      rating: 4
                    },
                    {
                      id: 7,
                      productName: "Cappuccino",
                      rates: 68,
                      comments: 21,
                      rating: 4
                    },
                    {
                      id: 8,
                      productName: "Latte Macchiato",
                      rates: 43,
                      comments: 10,
                      rating: 3
                    },
                    {
                      id: 9,
                      productName: "Americano",
                      rates: 78,
                      comments: 15,
                      rating: 3
                    },
                    {
                      id: 10,
                      productName: "Flat White",
                      rates: 28,
                      rating: 5
                    },
                    {
                      id: 11,
                      productName: "Mocha Latte",
                      rates: 19,
                      rating: 4
                    },
                    {
                      id: 12,
                      productName: "Iced Coffee",
                      rates: 57,
                      comments: 14,
                      rating: 3
                    },
                    {
                      id: 13,
                      productName: "Caramel Macchiato",
                      rates: 38,
                      comments: 9,
                      rating: 4
                    },
                    {
                      id: 14,
                      productName: "Cinnamon Latte",
                      rates: 12,
                      rating: 5
                    },
                    {
                      id: 15,
                      productName: "Turkish Coffee",
                      rates: 93,
                      comments: 25,
                      rating: 3
                    }
                  ]}
                />
              }
            />
        </div>
      </div>
    </Layout>
  );
}

export default ProductHighlights;
