import styles from "./Sidebar.module.scss";
import SidebarLogo from "../../assets/svgs/sidebar_logo.svg";
import { sidebarElements } from "../../routes/SidebarElements";
import { useNavigate } from "react-router-dom";
import checkmark from "../../assets/icons/checkmark.svg";
import arrow from "../../assets/icons/arrow.svg";
import selectArrow from "../../assets/icons/select_arrow.svg";
import { useState } from "react";

function Sidebar() {
  const navigate = useNavigate();
  const [sideBarElements, setSidebarElements] = useState([]);

  const currentPath = window.location.pathname;

  const getSideBarElements = () => {
    return sidebarElements?.map((item, i) => {
      return (
        <div key={i}>
          <div
            className={`${styles.sidebar_element} ${
              currentPath === item.path && styles.active
            } ${!item.icon && styles.no_icon}`}
            onClick={() => {
              navigate(item.path);
            }}
          >
            <img src={item.icon} alt="icon" />
            <span className={styles.label}>{item.text}</span>
            <img className={styles.arrow} src={selectArrow} alt="open" />
          </div>
          {item.children &&
            item.children?.map((child, childIndex) => {
              return (
                <div
                  className={`${styles.sidebar_element} ${
                    currentPath === child.path && styles.active
                  } ${!child.icon && styles.no_icon}`}
                  key={childIndex}
                  onClick={() => {
                    navigate(child.path);
                  }}
                >
                  <span className={styles.label}>{child.text}</span>
                </div>
              );
            })}
        </div>
      );
    });
  };

  useState(() => {
    setSidebarElements(getSideBarElements());
  }, []);

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebar_container}>
        <div className={styles.sidebar_logo_container}>
          <img src={SidebarLogo} alt="sidebar-logo" />
        </div>
        <div className={styles.sidebar_elements}>{sideBarElements}</div>
        <div className={styles.upgrade_container}>
          <div className={styles.title}>
            Upgrade <br /> to <span>PRO</span>
          </div>
          <div className={styles.checkmarks}>
            <div className={styles.item}>
              <img src={checkmark} alt="checkmark" />
              <span>Advanced features</span>
            </div>
            <div className={styles.item}>
              <img src={checkmark} alt="checkmark" />
              <span>Get more insights on your specific target</span>
            </div>
            <div className={styles.item}>
              <img src={checkmark} alt="checkmark" />
              <span>Unlock new menu features</span>
            </div>
          </div>
          <div className={styles.button}>
            Upgrade now
            <div className={styles.arrow_background}>
              <img src={arrow} alt="upgrade-now" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
