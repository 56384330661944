import Layout from "../../components/layout/Layout";
import styles from "./Dashboard.module.scss";
import arrow from "../../assets/icons/select_arrow_orange.svg";
import personTurquoise from "../../assets/icons/user_turquoise.svg";
import arrowLB from "../../assets/icons/select_arrow_light_brown.svg";
import coffeeCheck from "../../assets/svgs/coffee_check.svg";
import coffeeStar from "../../assets/svgs/coffee_star.svg";
import coffeeDragon from "../../assets/svgs/coffee_dragon.svg";
import coffeeLocation from "../../assets/svgs/coffee_location.svg";
import personCoffee from "../../assets/svgs/person_coffee.svg";
import personCoffeePlus from "../../assets/svgs/person_coffee_plus.svg";
import ShortCard from "../../components/infoCard/InfoCard";
import InsightsCard from "../../components/insightsCard/InsightsCard";
import SimpleCard from "../../components/simpleCard/SimpleCard";
import BarGraph from "../../components/barGraph/BarGraph";
import { useState } from "react";
import coffee from "../../assets/svgs/coffee_flowers.svg";
import DistributionGraph from "../../components/distributionGraph/DistributionGraph";
import LineGraph from "../../components/lineGraph/LineGraph";
import PopularTable from "../../components/popularTable/PopularTable";
import ProductAttention from "../../components/productAttention/ProductAttention";

function Dashboard() {
  const [barGraphData] = useState([
    { id: 1, value: 90, label: "Mon" },
    { id: 2, value: 170, label: "Tue" },
    {
      id: 3,
      value: 250,
      label: "Wed",
      name: "Cinnamon Capuccino",
      unit: "check-ins",
      icon: coffeeDragon,
    },
    { id: 4, value: 60, label: "Thu" },
    { id: 5, value: 170, label: "Fri" },
    { id: 6, value: 190, label: "Sat" },
    { id: 7, value: 65, label: "Sun" },
  ]);

  const [productHighlightsHeight, setProductHighlightsHeight] = useState(null);

  function DashboardDropDown() {
    return (
      <div className={styles.dashboard_select}>
        This week
        <img src={arrow} alt="arrow" />
      </div>
    );
  }

  return (
    <Layout pageTitle="Dashboard" subTitle={<DashboardDropDown />}>
      <div className={styles.body}>
        <div className={styles.left}>
          <div className={styles.short_card_rows}>
            <ShortCard
              icon={coffeeCheck}
              title={"Check-ins"}
              number={260}
              change={5.4}
              lastWeek={176}
            />
            <ShortCard
              icon={personCoffee}
              title={"Profile visits"}
              number={"1,287"}
              change={37.4}
              lastWeek={176}
            />
            <ShortCard
              icon={personCoffeePlus}
              title={"New customers"}
              number={80}
              change={-20}
              lastWeek={100}
            />
          </div>
          <div className={styles.charts}>
            <SimpleCard
              title={"Product Highlights"}
              setHeightCallback={(height) => {
                setProductHighlightsHeight(height);
              }}
              className={styles.card}
              subtitle={
                <div className={styles.product_highlight_subtitle}>
                  Check-ins
                  <img src={arrowLB} alt="arrow" />
                </div>
              }
              body={
                <BarGraph
                  data={barGraphData}
                  highlight
                  popup
                  className={styles.card}
                />
              }
            />
            <SimpleCard
              title={"My customers"}
              height={productHighlightsHeight}
              className={styles.card}
              subtitle={
                <div className={styles.my_customers_subtitle}>
                  <p>Age</p>
                  <p>Check-ins</p>
                </div>
              }
              body={
                <DistributionGraph
                  percentage={42}
                  label={
                    <div className={styles.distribution_graph_label}>
                      <img src={personTurquoise} alt="person" />
                      <p>19-25</p>
                    </div>
                  }
                />
              }
            />
            <SimpleCard
              title={"My competitors"}
              height={productHighlightsHeight}
              className={styles.card}
              subtitle={"Check-ins"}
              body={<LineGraph />}
            />
          </div>
          <div className={styles.popular_menu}>
            <SimpleCard
              title={"Most popular menu items"}
              className={styles.popular_menu_card}
              body={
                <PopularTable
                  data={[
                    {
                      productName: "Vanilla Capuccino",
                      shares: "1,089",
                      checkIns: "700",
                    },
                    {
                      productName: "Mocha Coffee",
                      shares: "1,044",
                      checkIns: "233",
                    },
                    {
                      productName: "Café con leche",
                      shares: "1,300",
                      checkIns: "101",
                    },
                    {
                      productName: "Vanilla Capuccino",
                      shares: "120",
                      checkIns: "10",
                    },
                  ]}
                />
              }
            />
            <ProductAttention name={"Café con leche"} image={coffee} />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.insight_card}>
            <InsightsCard
              title="Cupp'd Insights"
              subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
              data={[
                {
                  id: 0,
                  icon: coffeeCheck,
                  title: "Check-ins",
                  number: "10,326",
                  change: 5.4,
                },
                {
                  id: 1,
                  icon: coffeeStar,
                  title: "Star Product",
                  number: "2,293",
                  label: "Mocha Coffee",
                },
                {
                  id: 2,
                  icon: coffeeLocation,
                  title: "Winner Location",
                  number: "4,389",
                  label: "Virginia, USA",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;
