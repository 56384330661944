import styles from "./PopularTable.module.scss";
import starT from "../../assets/icons/star_turquoise.svg";
import starBr from "../../assets/icons/star_brown_2.svg";
import starBl from "../../assets/icons/star_black.svg";
import selectArrow from "../../assets/icons/select_arrow.svg";

function PopularTable(props) {
  const { data } = props;
  return (
    <table className={styles.popular_table_container}>
      <thead>
        <tr>
            <th></th>
            <th>Product name </th>
            <th><div className={styles.header}>Shares <img src={selectArrow} alt={"arrow"} /></div></th>
            <th><div className={styles.header}>Check-ins <img src={selectArrow} alt={"arrow"} /></div></th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          return <tr key={index}>
            <td className={index === 0 ? styles.first : index === 1 ? styles.second : ''}><img src={index === 0 ? starT : index === 1 ? starBr : starBl} alt="star" />{' ' + (index + 1)}</td>
            <td>{item.productName}</td>
            <td>{item.shares}</td>
            <td>{item.checkIns}</td>
          </tr>;
        })}
      </tbody>
    </table>
  );
}

export default PopularTable;
