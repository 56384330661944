import styles from "./Input.module.scss";

function Input(props) {
  const {
    type,
    id,
    placeholder,
    label,
    labelOutsideInput,
    inputClassName,
    data,
  } = props;

  if (type === "select") {
    return (
      <>
        <div className={`${styles.input_container}`}>
          <select id={id} className={`${styles.input} ${styles.select} ${inputClassName}`}>
            {data.map((item, idx) => {
              return <option key={idx} value={item.value}>{item.label}</option>;
            })}
          </select>
        </div>
      </>
    );
  }

  return (
    <>
      {labelOutsideInput && (
        <span className={styles.label_outside}>{label}</span>
      )}
      <div
        className={`${styles.input_container} ${
          labelOutsideInput && styles.has_outside_label
        }`}
      >
        {!labelOutsideInput && <span className={styles.label}>{label}</span>}
        <input
          className={`${styles.input} ${
            type === "textarea" && styles.textarea
          } ${inputClassName}`}
          type={type}
          id={id}
          placeholder={placeholder}
        />
      </div>
    </>
  );
}

export default Input;
