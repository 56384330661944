import styles from "./InsightsCard.module.scss";
import arrowRight from "../../assets/icons/arrow_right.svg";
import Button from "../button/Button";

function InsightsCard(props) {
  const { title, subtitle, data } = props;

  return (
    <div className={styles.card_container}>
      <div className={styles.title}>
        {title}
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
      <div className={styles.items_container}>
        {data.map((item) => {
          return (
            <div className={styles.item} key={item.id}>
              <div className={styles.icon}>
                <img src={item.icon} alt="icon" />
              </div>
              <div className={styles.info}>
                <div className={styles.item_title}>{item.title}</div>
                <div className={styles.item_number}>{item.number}</div>
                <div
                  className={`${styles.item_detail} ${
                    item.change
                      ? item.change > 0
                        ? styles.change_positive
                        : styles.change_negative
                      : styles.label
                  }`}
                >
                  {item.change
                    ? (item.change > 0 ? "+" : "-") + item.change + "%"
                    : item.label}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.footer}>
        Want to know more about what is going on in the coffee world?
        <Button
          type={1}
          className={styles.button}
          text={
            <div className={styles.button_label}>
              View More
              <div className={styles.icon}>
                <img src={arrowRight} alt="button-icon" />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default InsightsCard;
