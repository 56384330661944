import styles from "./Modal.module.scss";

function Modal(props) {
  const { isVisible, title, body, footer, closeCallback, largeModal } = props;

  const handleForegroundClick = (e) => {
    e.stopPropagation();
  };
  return (
    isVisible && (
      <div className={styles.modal_backdrop} onClick={closeCallback}>
        <div
          className={`${styles.modal_container} ${
            largeModal && styles.large_modal
          }`}
          onClick={handleForegroundClick}
        >
          <div className={styles.title}>{title}</div>
          <div className={styles.body}>{body}</div>
          {footer && <div className={styles.footer}>{footer}</div>}
        </div>
      </div>
    )
  );
}

export default Modal;
