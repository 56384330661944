import styles from "./ProductAttention.module.scss";
import arrow from "../../assets/icons/arrow.svg";
import swirl from "../../assets/svgs/swirl.svg";

function ProductAttention(props) {
  const { name, image } = props;
  return (
    <div className={styles.product_attention_container}>
      <div className={styles.row}>
        <div className={styles.line}>
          <img src={swirl} alt={"swirl"} />
        </div>
        <div className={styles.image}>
          <img src={image} alt={"product"} />
        </div>
        <div className={styles.info}>
          <div className={styles.text}>
            Looks like your product "<span>{name}</span>" is getting attention!
          </div>
          <div className={styles.button}>
            See suggestion
            <div className={styles.arrow_background}>
              <img src={arrow} alt="arrow" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductAttention;
