import styles from "./InfoCard.module.scss";

function InfoCard(props) {
  const { icon, title, number, change, lastWeek } = props;
  const isPositiveChange = change && change > 0;
  return (
    <div className={styles.card_container}>
      <div className={styles.card_body_container}>
        <div className={styles.icon}>
          <img src={icon} alt="icon" />
        </div>
        <div className={styles.card_info_container}>
          <div className={styles.title}>{title}</div>
          <div className={styles.number}>
            {number}
            <div
              className={`${styles.change} ${
                isPositiveChange ? styles.positive : styles.negative
              }`}
            >
              {isPositiveChange ? "+" : ""}
              {change}%
            </div>
          </div>
          <div className={styles.last_week}>Last week {lastWeek}</div>
        </div>
      </div>
    </div>
  );
}

export default InfoCard;
