import styles from "./MyCustomers.module.scss";
import Layout from "../../components/layout/Layout";
import arrow from "../../assets/icons/select_arrow_orange.svg";
import personTurquoise from "../../assets/icons/user_turquoise.svg";
import instagramTurquoise from "../../assets/svgs/instagram_turquoise.svg";
import coffeeFlowers from "../../assets/svgs/coffee_flowers.svg";
import girlCoffeePhone from "../../assets/svgs/girl_coffee_phone.svg";
import { useState } from "react";
import DistributionGraph from "../../components/distributionGraph/DistributionGraph";
import SimpleCard from "../../components/simpleCard/SimpleCard";
import BarGraph from "../../components/barGraph/BarGraph";
import TargetAttention from "../../components/targetAttention.js/TargetAttention";

function MyCustomers() {
  const [cardsHeight, setCardsHeight] = useState(null);

  const [ageBarGraphData] = useState([
    { id: 0, value: 30, label: "1:00am" },
    { id: 1, value: 80, label: "2:00am" },
    { id: 2, value: 120, label: "3:00am" },
    { id: 3, value: 90, label: "4:00am" },
    { id: 4, value: 60, label: "5:00am" },
    { id: 5, value: 90, label: "6:00am" },
    { id: 6, value: 170, label: "7:00am" },
    { id: 7, value: 250, label: "8:00am" },
    { id: 8, value: 60, label: "9:00am" },
    { id: 9, value: 170, label: "10:00am" },
    { id: 10, value: 190, label: "11:00am" },
    { id: 11, value: 65, label: "12:00pm" },
    { id: 12, value: 160, label: "1:00pm" },
    { id: 13, value: 200, label: "2:00pm" },
    { id: 14, value: 240, label: "3:00pm" },
    { id: 15, value: 110, label: "4:00pm" },
    { id: 16, value: 70, label: "5:00pm" },
    { id: 17, value: 150, label: "6:00pm" },
    { id: 18, value: 220, label: "7:00pm" },
    { id: 19, value: 290, label: "8:00pm", icon: personTurquoise },
    { id: 20, value: 200, label: "9:00pm" },
    { id: 21, value: 130, label: "10:00pm" },
    { id: 22, value: 70, label: "11:00pm" },
  ]);

  function DashboardSubtitle() {
    return (
      <>
        <span className={styles.page_subtitle_2}>My Customers</span>
        <div className={styles.dashboard_select}>
          This week
          <img src={arrow} alt="arrow" />
        </div>
      </>
    );
  }

  return (
    <Layout pageTitle="Dashboard | " subTitle={<DashboardSubtitle />}>
      <div className={styles.body}>
        <div className={styles.left}>
          <div className={styles.chart}>
            <SimpleCard
              title={"My customers"}
              setHeightCallback={(height) => {
                if (height > cardsHeight) setCardsHeight(height);
              }}
              height={cardsHeight}
              className={styles.card}
              subtitle={
                <div className={styles.subtitle}>
                  <p>Age</p>
                  <p>Check-ins</p>
                </div>
              }
              body={
                <DistributionGraph
                  percentage={42}
                  label={
                    <div className={styles.distribution_graph_label}>
                      <img src={personTurquoise} alt="person" />
                      <p>19-25</p>
                    </div>
                  }
                />
              }
            />
          </div>
          <div className={styles.chart}>
            <SimpleCard
              title={"Check-in Heatmap"}
              setHeightCallback={(height) => {
                if (height + 30 > cardsHeight) setCardsHeight(height + 30);
              }}
              height={cardsHeight}
              className={`${styles.card} ${styles.large_card}`}
              subtitle={"Age"}
              body={
                <BarGraph
                  data={ageBarGraphData}
                  highlight
                  hideSomeLabels
                  verticalLabels
                  className={styles.large_card}
                  smallIcon={personTurquoise}
                  smallIconText={"19-25"}
                />
              }
              footer={
                <div className={styles.bar_graph_footer}>
                  <div>Mon</div>
                  <div>Tue</div>
                  <div className={styles.today}>Wed</div>
                  <div>Thu</div>
                  <div>Fri</div>
                  <div>Sat</div>
                  <div>Sun</div>
                </div>
              }
            />
          </div>
          <div className={styles.chart}>
            <SimpleCard
              title={"Shares"}
              setHeightCallback={(height) => {
                if (height > cardsHeight) setCardsHeight(height);
              }}
              height={cardsHeight}
              className={styles.card}
              subtitle={"Age"}
              body={
                <div className={styles.shares_chart}>
                  <DistributionGraph
                    percentage={69}
                    horizontal
                    label={
                      <div className={styles.distribution_graph_label}>
                        <img src={personTurquoise} alt="person" />
                        <p>15-18</p>
                      </div>
                    }
                  />
                  <div className={styles.favorites}>
                    <div className={styles.favorite}>
                      <div className={styles.title}>Their favorite product</div>
                      <div className={styles.favorite_product}>
                        <img src={coffeeFlowers} alt="fav-prod" />
                        <p className={styles.label}>Vanilla Capuccino</p>
                      </div>
                    </div>
                    <div className={styles.favorite}>
                      <div className={styles.title}>Their favorite channel</div>
                      <div className={styles.favorite_product}>
                        <img src={instagramTurquoise} alt="fav-prod" />
                        <p className={styles.label}>Instagram Stories</p>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
          <div className={styles.chart}>
            <SimpleCard
              title={"Check-ins"}
              setHeightCallback={(height) => {
                if (height > cardsHeight) setCardsHeight(height);
              }}
              height={cardsHeight}
              className={styles.card}
              subtitle={"Gender"}
              body={
                <BarGraph
                  data={[
                    { id: 0, value: 35, label: "Men" },
                    { id: 1, value: 65, label: "Women" },
                  ]}
                  lightBrown
                  highlight
                  className={styles.gender_chart}
                  valueOnBarGraph
                />
              }
            />
          </div>
          <div className={styles.chart}>
            <SimpleCard
              title={"Check-in Heatmap"}
              setHeightCallback={(height) => {
                if (height + 30 > cardsHeight) setCardsHeight(height + 30);
              }}
              height={cardsHeight}
              className={`${styles.card} ${styles.large_card}`}
              subtitle={"Gender"}
              body={
                <BarGraph
                  data={ageBarGraphData}
                  highlight
                  hideSomeLabels
                  verticalLabels
                  className={styles.large_card}
                  smallIconText={"Women"}
                  lightBrown
                  highlightedSmallIcon
                />
              }
              footer={
                <div className={styles.bar_graph_footer}>
                  <div>Mon</div>
                  <div>Tue</div>
                  <div className={`${styles.today} ${styles.light_brown}`}>
                    Wed
                  </div>
                  <div>Thu</div>
                  <div>Fri</div>
                  <div>Sat</div>
                  <div>Sun</div>
                </div>
              }
            />
          </div>
          <div className={styles.chart}>
            <SimpleCard
              title={"Shares"}
              setHeightCallback={(height) => {
                if (height > cardsHeight) setCardsHeight(height);
              }}
              height={cardsHeight}
              className={styles.card}
              subtitle={"Channel"}
              body={
                <div className={styles.shares_channel_chart}>
                  <DistributionGraph
                    percentage={46}
                    horizontal
                    label={
                      <div className={styles.distribution_shares_graph_label}>
                        <div className={styles.row}>
                          <img src={instagramTurquoise} alt="icon" />
                          <p className={styles.label}>Instagram Stories</p>
                        </div>
                      </div>
                    }
                  />
                  <div className={styles.shares_chart_footer}>
                    <div className={styles.favorite}>
                      <div className={styles.title}>Their age</div>
                      <div
                        className={`${styles.favorite_product} ${styles.age}`}
                      >
                        <img src={personTurquoise} alt="icon" />
                        <p className={styles.label}>15-18</p>
                      </div>
                    </div>
                    <div className={styles.favorite}>
                      <div className={styles.title}>Their favorite product</div>
                      <div className={styles.favorite_product}>
                        <img src={coffeeFlowers} alt="fav-prod" />
                        <p className={styles.label}>Vanilla Capuccino</p>
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        </div>
        <div className={styles.right}>
          <TargetAttention image={girlCoffeePhone} />
        </div>
      </div>
    </Layout>
  );
}

export default MyCustomers;
