import { CUPPD_INSIGHTS, DASHBOARD, MENU, MY_BUSINESS, MY_CUSTOMERS, PRODUCT_HIGHLIGHTS, PROFILE } from "./Routes";
import signal from "../assets/icons/signal.svg";
import person from "../assets/icons/person.svg";

export const sidebarElements = [
  {
    path: DASHBOARD,
    icon: signal,
    text: "Dashboard",
    children: [
      {
        path: PRODUCT_HIGHLIGHTS,
        text: "Product Highlights",
      },
      {
        path: MY_CUSTOMERS,
        text: "My Customers",
      },
      {
        path: CUPPD_INSIGHTS,
        text: "Cupp'd Insights",
      },
    ],
  },
  {
    path: MY_BUSINESS,
    icon: person,
    text: "My Business",
    children: [
      {
        path: MENU,
        text: "Menu",
      },
      {
        path: PROFILE,
        text: "Profile",
      },
    ]
  }
];
