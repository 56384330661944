import styles from "./Button.module.scss";

function Button(props) {
  const { type, text, className, onClick } = props;

  let style;
  //Black with white text
  if (type === 1 || !type) {
    style = `${className} ${styles.black_white}`;
  }

  return (
    <button type="button" className={`${styles.button} ${style}`} onClick={onClick}>
      {text}
    </button>
  );
}

export default Button;
