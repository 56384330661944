import styles from "./BarGraph.module.scss";
import star from "../../assets/icons/star_white.svg";
import { useEffect, useRef, useState } from "react";

function BarGraph(props) {
  const {
    data,
    highlight,
    popup,
    className,
    lightBrown,
    hideSomeLabels,
    verticalLabels,
    smallIcon,
    smallIconText,
    valueOnBarGraph,
    highlightedSmallIcon
  } = props;

  const highlightedRef = useRef(null);
  const containerRef = useRef(null);
  const [graphWidth, setBarGraphWidth] = useState(0);
  const dataLength = data.length;

  //if hideSomeLabels
  const indicesToShow = [];
  for (let i = 1; i <= 3; i++) {
    const idx = Math.round((i * dataLength) / 3) - 1;
    indicesToShow.push(idx);
  }

  const highestValueObject = data.reduce(
    (highestObject, currentItem) => {
      if (currentItem.value > highestObject.value) {
        return currentItem;
      } else {
        return highestObject;
      }
    },
    { id: 0, value: -Infinity }
  );

  const highestValueIndex = data.reduce(
    (maxIndexSoFar, currentValue, currentIndex, array) => {
      if (currentValue.value > array[maxIndexSoFar].value) {
        return currentIndex;
      } else {
        return maxIndexSoFar;
      }
    },
    0
  );

  const getPopupOrientation = () => {
    const arrayLength = data.length;
    const firstThirdEndIndex = Math.floor(arrayLength / 3) - 1;
    const lastThirdStartIndex = firstThirdEndIndex * 2 + 1;

    if (highestValueIndex <= firstThirdEndIndex) {
      return "flex-start";
    } else if (highestValueIndex <= lastThirdStartIndex) {
      return "center";
    } else {
      return "flex-end";
    }
  };

  const getLabels = (width) => {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      if (indicesToShow.includes(i)) {
        result.push(
          <div
            key={i}
            className={styles.vertical_label}
            style={{ width: width + "px" }}
          >
            {data[i].label}
          </div>
        );
      } else {
        result.push(
          <div
            key={i}
            className={styles.vertical_label}
            style={{ width: width + "px" }}
          >
            {"⠀"}
          </div>
        );
      }
    }
    return result;
  };

  useEffect(() => {
    let barWidth = 0;
    if (data.length < 10) {
      barWidth = containerRef?.current?.offsetWidth / data.length - 12 - 8;
    } else {
      barWidth = containerRef?.current?.offsetWidth / data.length;
    }
    setBarGraphWidth(barWidth);
  }, [data.length]);

  return (
    <div
      className={`${styles.bar_graph_container} ${className}`}
      ref={containerRef}
    >
      {popup && (
        <div
          className={`${styles.popup_container}`}
          style={{ justifyContent: getPopupOrientation() }}
        >
          <div
            className={`${styles.popup} ${lightBrown && styles.light_brown}`}
          >
            <div className={styles.info}>
              <div className={styles.title}>
                <div className={styles.star}>
                  <img src={star} alt="star" />
                </div>
                {highestValueObject.name}
              </div>
              <div className={styles.subtitle}>
                {highestValueObject.value}
                <div className={styles.unit}>{highestValueObject.unit}</div>
              </div>
            </div>
            <div className={styles.icon}>
              <img src={highestValueObject.icon} alt="icon" />
            </div>
          </div>
        </div>
      )}
      <div className={styles.bars}>
        {data.map((item, index) => {
          return (
            <div
              className={`${styles.bar_container} ${
                data.length > 10 && styles.large_count
              }`}
              key={item.id}
            >
              {popup && highestValueObject.id === item.id && (
                <div
                  className={`${styles.dotted_line} ${
                    lightBrown && styles.light_brown
                  }`}
                ></div>
              )}
              {smallIcon && index === highestValueIndex && (
                <div className={styles.small_icon_container}>
                  <div className={styles.small_icon}>
                    <img src={smallIcon} alt="smallIcon" />
                    <p>{smallIconText}</p>
                  </div>
                </div>
              )}
              {highlightedSmallIcon && index === highestValueIndex && (
                <div className={styles.small_icon_container}>
                  <div className={styles.small_icon}>
                    <div className={styles.highlighted_small_icon}>{item.value}</div>
                    <p className={styles.bold}>{smallIconText}</p>
                  </div>
                </div>
              )}
              <div
                className={`${styles.bar} ${
                  highlight &&
                  highestValueObject.value === item.value &&
                  styles.highlighted
                } ${lightBrown && styles.light_brown}`}
                key={item.id}
                ref={
                  popup && highestValueObject.value === item.value
                    ? highlightedRef
                    : null
                }
                style={{
                  height:
                    parseFloat(item.value / highestValueObject.value).toFixed(
                      2
                    ) *
                      100 +
                    "px",
                  width: graphWidth + "px", //12 for padding, 8 for a gap
                  marginTop:
                    verticalLabels && indicesToShow.includes(index)
                      ? item.label.length * 2 + "px"
                      : "10px",
                }}
              >
                {valueOnBarGraph && (
                  <div
                    className={`${styles.value_on_bar} ${
                      highlight &&
                      highestValueObject.value === item.value &&
                      styles.active
                    }`}
                  >
                    {item.value}%
                  </div>
                )}
              </div>
              {!hideSomeLabels && (
                <div
                  className={`${styles.label} ${
                    highlight &&
                    highestValueObject.value === item.value &&
                    styles.highlighted
                  } ${lightBrown && styles.light_brown}
                `}
                >
                  {item.label}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {hideSomeLabels && (
        <div className={styles.vertical_labels}>
          {getLabels(containerRef?.current?.offsetWidth / data.length)}
        </div>
      )}
    </div>
  );
}

export default BarGraph;
