import styles from "./TargetAttention.module.scss";
import arrow from "../../assets/icons/arrow.svg";
import swirl from "../../assets/svgs/swirl.svg";

function TargetAttention(props) {
  const { image } = props;
  return (
    <div className={styles.product_attention_container}>
      <div className={styles.bg}>
        <div className={styles.row}>
          <div className={styles.line}>
            <img src={swirl} alt={"swirl"} />
          </div>
          <div className={styles.image}>
            <img src={image} alt={"product"} />
          </div>
          <div className={styles.info}>
            <div className={styles.text}>
              Seems like your target is going in a different direction!
            </div>
            <div className={styles.bottom_text}>
              Be one step ahead of your competitors!
            </div>
            <div className={styles.button}>
              See Ad suggestion
              <div className={styles.arrow_background}>
                <img src={arrow} alt="arrow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TargetAttention;
