import { useEffect, useRef } from "react";
import styles from "./SimpleCard.module.scss";

function SimpleCard(props) {
  const { title, subtitle, body, setHeightCallback, height, className, footer } = props;

  const bodyRef = useRef(null);

  useEffect(() => {
    if (setHeightCallback) setHeightCallback(bodyRef.current.clientHeight + 40);
  }, []); //eslint-disable-line

  return (
    <div
      className={`${styles.simple_card_container} ${className}`}
      style={{ height: height + "px" }}
    >
      <div className={`${styles.title} ${className}`}>
        {title}
        <div className={styles.subtitle}>{subtitle}</div>
      </div>
      <div className={`${styles.body} ${className}`} ref={bodyRef}>
        {body}
      </div>
      <div className={styles.footer}>
        {footer}
      </div>
    </div>
  );
}

export default SimpleCard;
