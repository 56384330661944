import styles from "./Menu.module.scss";
import Layout from "../../components/layout/Layout";
import browse from "../../assets/icons/browse.svg";
import arrow from "../../assets/icons/select_arrow.svg";
import SimpleCard from "../../components/simpleCard/SimpleCard";
import { useState } from "react";
import starBr from "../../assets/icons/star_brown_2.svg";
import starGray from "../../assets/icons/star_gray.svg";
import defaultItem from "../../assets/svgs/default_item.svg";
import coldBrew from "../../assets/svgs/cold_brew.svg";
import frapuccino from "../../assets/svgs/frapuccino.svg";
import pumpkinLatte from "../../assets/svgs/pumpkin_latte.svg";
import Button from "../../components/button/Button";
import Modal from "../../components/modal/Modal";
import Input from "../../components/input/Input";
import Dropzone from "../../components/dropzone/Dropzone";
import coffeeDragon from "../../assets/svgs/coffee_dragon.svg";
import coffeeFlowers from "../../assets/svgs/coffee_flowers.svg";

function Menu() {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(!modalOpen);
  };

  const onUploadCallback = () => {};

  const AddNewMenuItemModalBody = () => {
    return (
      <div className={styles.add_new_menu_item_container}>
        <div className={`${styles.description} ${styles.card}`}>
          <div className={styles.title}>Description</div>
          <div className={styles.inputs}>
            <div className={styles.input_container}>
              <Input
                type="text"
                id="itemName"
                label="* Item name"
                labelOutsideInput
                inputClassName={styles.input}
              />
            </div>
            <div className={styles.input_container}>
              <Input
                type="textarea"
                id="description"
                label="* Description"
                labelOutsideInput
                inputClassName={styles.input}
              />
            </div>
            <div className={styles.input_container}>
              <Input
                type="number"
                id="price"
                label="Price"
                labelOutsideInput
                inputClassName={styles.input}
              />
            </div>
          </div>
        </div>
        <div className={`${styles.address}  ${styles.card}`}>
          <div className={styles.title}>Category</div>
          <div className={styles.inputs}>
            <div className={styles.input_container}>
              <Input
                type="select"
                id="category"
                label="* Address Line 1"
                placeholder="Choose category"
                labelOutsideInput
                inputClassName={styles.input}
                data={[
                  {
                    label: "A",
                    value: 1,
                  },
                  {
                    label: "B",
                    value: 2,
                  },
                  {
                    label: "C",
                    value: 3,
                  },
                ]}
              />
            </div>
            <div className={styles.input_container}>
              <div className={`${styles.title} ${styles.item_display_picture}`}>
                Item display picture
              </div>
              <div className={styles.dropzone_container}>
                <Dropzone onUploadCallback={onUploadCallback} />
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.profile_picture}  ${styles.card}`}>
          <div className={styles.title}>Other pictures</div>
          <div className={styles.other_pictures}>
            <img src={coffeeDragon} alt="icon" />
            <img src={coffeeFlowers} alt="icon" />
            <img src={coldBrew} alt="icon" />
          </div>
          <div className={styles.inputs}>
            <div className={styles.input_container}>
              <div className={styles.dropzone_container}>
                <Dropzone onUploadCallback={onUploadCallback} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const AddNewMenuItemModalFooter = () => {
    return <Button type={1} text={"Save changes"} />;
  };

  const [tableData] = useState([
    {
      icon: pumpkinLatte,
      label: "Pumpkin Latte",
      category: "Hot drinks",
      price: 2,
      order: 3,
      rating: 5,
    },
    {
      icon: defaultItem,
      label: "Name Item",
      category: "Hot drinks",
      price: 7.15,
      order: 12,
      rating: 4,
    },
    {
      icon: frapuccino,
      label: "Frapuccino",
      category: "Cold drinks",
      price: 3,
      order: 34,
      rating: 4,
    },
    {
      icon: coldBrew,
      label: "Cold Brew",
      category: "Food",
      price: 2,
      order: 10,
      rating: 3,
    },
    {
      icon: defaultItem,
      label: "Name Item",
      category: "Hot drinks",
      price: 11,
      order: 40,
      rating: 4,
    },
  ]);

  const getStars = (amountOfFilledStars) => {
    const filledStars = Math.floor(amountOfFilledStars);
    const remainingStars = 5 - filledStars;

    const stars = [];

    for (let i = 0; i < filledStars; i++)
      stars.push(<img key={i + "_" + starBr} src={starBr} alt="star" />);

    for (let i = 0; i < remainingStars; i++)
      stars.push(<img key={i + "_" + starGray} src={starGray} alt="star" />);

    return <div>{stars}</div>;
  };

  return (
    <>
      <Layout
        pageTitle="My Business | Menu"
        subTitle={
          <div className={styles.dashboard_select}>
            <img src={browse} alt="arrow" />
            <span>Preview</span>
          </div>
        }
      >
        <div className={styles.body}>
          <SimpleCard
            className={styles.table_card}
            height={437}
            title={
              <div className={styles.table_header}>
                <div className={styles.item_header}>
                  Item
                  <img src={arrow} alt="arrow" />
                </div>
                <div className={styles.category_header}>
                  Category
                  <img src={arrow} alt="arrow" />
                </div>
                <div className={styles.price_header}>
                  Price
                  <img src={arrow} alt="arrow" />
                </div>
                <div className={styles.order_header}>
                  Order
                  <img src={arrow} alt="arrow" />
                </div>
                <div className={styles.rating_header}>
                  Rating
                  <img src={arrow} alt="arrow" />
                </div>
              </div>
            }
            body={
              <>
                <div className={styles.table_body_container}>
                  {tableData?.map((item, idx) => {
                    return (
                      <div className={styles.table_row} key={idx}>
                        <div className={styles.item_cell}>
                          <img src={item.icon} alt="product-icon" />
                          <span>{item.label}</span>
                        </div>
                        <div className={styles.category_cell}>
                          <span>{item.category}</span>
                        </div>
                        <div className={styles.price_cell}>
                          <span>USD${parseFloat(item.price).toFixed(2)}</span>
                        </div>
                        <div className={styles.order_cell}>
                          <span>{item.order}</span>
                        </div>
                        <div className={styles.rating_cell}>
                          <span>{getStars(item.rating)}</span>
                        </div>
                        <div className={styles.edit_cell}>
                          <span className={styles.edit}>Edit</span>
                          <span className={styles.delete}>Delete</span>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.button}>
                  <Button
                    text="Add New Item"
                    onClick={() => {
                      setModalOpen(true);
                    }}
                  />
                </div>
              </>
            }
          />
        </div>
      </Layout>
      <Modal
        title={"Add New Menu Item"}
        isVisible={modalOpen}
        closeCallback={openModal}
        largeModal
        body={<AddNewMenuItemModalBody />}
        footer={<AddNewMenuItemModalFooter />}
      />
    </>
  );
}

export default Menu;
