import styles from "./DistributionGraph.module.scss";
import graph from "../../assets/svgs/distribution_graph.svg";
import horizontal1 from "../../assets/icons/horizontal_1.svg";
import horizontal2 from "../../assets/icons/horizontal_2.svg";
import horizontal3 from "../../assets/icons/horizontal_3.svg";
import horizontal4 from "../../assets/icons/horizontal_4.svg";

function DistributionGraph(props) {
  const { percentage, label, horizontal } = props;
  return (
    <div className={`${styles.distribution_graph_container} ${horizontal && styles.horizontal_container}`}>
      {horizontal ? (
        <div className={styles.horizontal_chart}>
          <div className={styles.label}>{label}</div>
          <div className={`${styles.chart}`}>
            <img src={horizontal1} alt="horizontal1" />
            <p className={styles.percentage}>{percentage}%</p>
            <img src={horizontal2} alt="horizontal2" />
            <img src={horizontal3} alt="horizontal3" />
            <img src={horizontal4} alt="horizontal4" />
          </div>
        </div>
      ) : (
        <>
          <div className={styles.chart}>
            <img src={graph} alt="graph" />
            <p className={styles.percentage}>{percentage}%</p>
          </div>
          <div className={styles.label}>{label}</div>
        </>
      )}
    </div>
  );
}

export default DistributionGraph;
