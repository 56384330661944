import styles from "./RatingsTable.module.scss";
import starBr from "../../assets/icons/star_brown_2.svg";
import starGray from "../../assets/icons/star_gray.svg";
import selectArrow from "../../assets/icons/select_arrow.svg";

function RatingsTable(props) {
  const { data } = props;

  const getStars = (amountOfFilledStars) => {
    const filledStars = Math.floor(amountOfFilledStars);
    const remainingStars = 5 - filledStars;

    const stars = [];

    for (let i = 0; i < filledStars; i++)
      stars.push(<img key={i + '_' + starBr} src={starBr} alt="star" />);

    for (let i = 0; i < remainingStars; i++)
      stars.push(<img key={i + '_' + starGray} src={starGray} alt="star" />);

    return <div>{stars}</div>;
  };

  return (
    <table className={styles.popular_table_container}>
      <thead>
        <tr>
          <th>Product Name</th>
          <th>
            <div className={styles.header}>
              Rates <img src={selectArrow} alt={"arrow"} />
            </div>
          </th>
          <th>
            <div className={styles.header}>
              Comments <img src={selectArrow} alt={"arrow"} />
            </div>
          </th>
          <th>
            <div className={styles.header}>
              Rating <img src={selectArrow} alt={"arrow"} />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          return (
            <tr key={item.id}>
              <td>{item.productName}</td>
              <td>{item.rates}</td>
              <td>{item.comments ? item.comments : "-"}</td>
              <td>{getStars(item.rating)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default RatingsTable;
