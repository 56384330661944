import styles from "./Header.module.scss";
import starbucks from "../../assets/svgs/starbucks.svg";
import Select from "../select/Select";
import { useState } from "react";
import Notifications from "../notifications/Notifications";
import person from "../../assets/icons/person.svg";
import cog from "../../assets/icons/cog.svg";
import logout from "../../assets/icons/logout.svg";
import plusRed from "../../assets/icons/plus_red.svg";
import Modal from "../modal/Modal";
import Button from "../button/Button";
import Input from "../input/Input";
import Dropzone from "../dropzone/Dropzone";

function Header() {
  const [modalOpen, setModalOpen] = useState(false);
  const [business] = useState([
    {
      icon: starbucks,
      text: "Starbucks",
      secondaryText: "DO",
      hideThisElement: true,
    },
    {
      icon: person,
      text: "My account",
    },
    {
      icon: cog,
      text: "Settings",
    },
    {
      icon: logout,
      text: "Log out",
    },
  ]);

  const openModal = () => {
    setModalOpen(!modalOpen);
  };

  const [branches] = useState([
    {
      text: "Branch Blue Mall",
    },
    {
      text: "Branch Santiago",
    },
    {
      text: "Branch La Vega",
    },
    {
      icon: starbucks,
      text: "All branches",
      brownOnSelect: true,
      fontColor: "lightBrown",
    },
    {
      icon: plusRed,
      text: "Add New",
      fontColor: "orange",
      dontChangeOnClick: true,
      onSelectCallback: openModal,
    },
  ]);

  const onUploadCallback = () => {};

  const AddNewBranchModalBody = () => {
    return (
      <div className={styles.add_new_branch_container}>
        <div className={`${styles.description} ${styles.card}`}>
          <div className={styles.title}>Description</div>
          <div className={styles.inputs}>
            <div className={styles.input_container}>
              <Input
                type="text"
                id="companyName"
                label="* Company name"
                labelOutsideInput
                inputClassName={styles.input}
              />
            </div>
            <div className={styles.input_container}>
              <Input
                type="textarea"
                id="description"
                label="* Description"
                labelOutsideInput
                inputClassName={styles.input}
              />
            </div>
            <div className={styles.input_container}>
              <Input
                type="time"
                id="schedule"
                label="* Schedule"
                labelOutsideInput
                inputClassName={styles.input}
              />
            </div>
          </div>
        </div>
        <div className={`${styles.address}  ${styles.card}`}>
          <div className={styles.title}>Address</div>
          <div className={styles.inputs}>
            <div className={styles.input_container}>
              <Input
                type="text"
                id="address1"
                label="* Address Line 1"
                labelOutsideInput
                inputClassName={styles.input}
              />
            </div>
            <div className={styles.input_container}>
              <Input
                type="text"
                id="address2"
                label="* Address Line 2"
                labelOutsideInput
                inputClassName={styles.input}
              />
            </div>
            <div className={styles.half_input_container}>
              <div className={styles.half}>
                <Input
                  type="text"
                  id="city"
                  label="* City"
                  labelOutsideInput
                  inputClassName={styles.input}
                />
              </div>
              <div className={styles.half}>
                <Input
                  type="text"
                  id="state"
                  label="* State"
                  labelOutsideInput
                  inputClassName={styles.input}
                />
              </div>
            </div>
            <div className={styles.half_input_container}>
              <div className={styles.half}>
                <Input
                  type="text"
                  id="country"
                  label="* Country"
                  labelOutsideInput
                  inputClassName={styles.input}
                />
              </div>
              <div className={styles.half}>
                <Input
                  type="number"
                  id="zip"
                  label="* ZIP Code"
                  labelOutsideInput
                  inputClassName={styles.input}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.profile_picture}  ${styles.card}`}>
          <div className={styles.title}>Profile Picture</div>
          <div className={styles.inputs}>
            <div className={styles.input_container}>
              <Input
                type="text"
                id="akjcbjk"
                label="* akjcbjk"
                labelOutsideInput
                inputClassName={styles.input}
              />
            </div>
            <div className={styles.input_container}>
              <span className={styles.label}>* Profile picture</span>
              <div className={styles.dropzone_container}>
                <Dropzone onUploadCallback={onUploadCallback} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const AddNewBranchModalFooter = () => {
    return <Button type={1} text={"Save changes"} />;
  };

  return (
    <>
      <Modal
        title={"Add New Branch"}
        isVisible={modalOpen}
        closeCallback={openModal}
        largeModal
        body={<AddNewBranchModalBody />}
        footer={<AddNewBranchModalFooter />}
      />
      <div className={styles.header_container}>
        <div className={styles.header}>
          <div className={styles.business}>
            <Select items={business} />
          </div>
          <div className={styles.branches}>
            <Select
              items={branches}
              placeholder={{
                icon: starbucks,
                text: "All branches",
                brownOnSelect: true,
              }}
            />
          </div>
          <div className={styles.notifications}>
            <Notifications amountOfUnreadNotifications={3} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
