import { useEffect, useRef, useState } from "react";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import styles from "./Layout.module.scss";

function Layout(props) {
  const { children, pageTitle, subTitle } = props;
  const [sidebarHeight, setSidebarHeight] = useState(null);
  const pageHeightRef = useRef(null);

  useEffect(() => {
    if (pageHeightRef.current) {
      setSidebarHeight(pageHeightRef.current.offsetHeight);
    }
  }, [pageHeightRef.current]); //eslint-disable-line

  return (
    <div className={styles.layout_container}>
      <div className={styles.header_container}>
        <Header />
      </div>
      <div className={styles.growable_container}>
        <div className={styles.sidebar_container} style={{
          height: (sidebarHeight + 104) + 'px'
        }}>
          <Sidebar />
        </div>
        <div className={styles.page_container} ref={pageHeightRef}>
          <div className={styles.page_header}>
            <div className={styles.page_title}>{pageTitle}</div>
            <div className={styles.page_subtitle}>{subTitle}</div>
          </div>
          <div className={styles.page_body_container}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
