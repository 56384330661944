import { useState } from "react";
import styles from "./Login.module.scss";
import Logo from "../../assets/svgs/logo.svg";
import Input from "../../components/input/Input";
import Button from "../../components/button/Button";
import { useNavigate } from "react-router-dom";
import { DASHBOARD } from "../../routes/Routes";

function Login() {
  const loginTab = 1,
    signUpTab = 2;

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(loginTab);

  return (
    <div className={styles.login_container}>
      <div className={styles.login_box}>
        <div className={styles.login_button_header}>
          <div
            className={`${styles.tab_button} ${
              activeTab === loginTab && styles.active_tab_button
            }`}
            onClick={() => {
              setActiveTab(loginTab);
            }}
          >
            Log in
          </div>
          <div
            className={`${styles.tab_button} ${
              activeTab === signUpTab && styles.active_tab_button
            }`}
            onClick={() => {
              setActiveTab(signUpTab);
            }}
          >
            Sign up
          </div>
        </div>
        <div className={styles.login_body}>
          <div className={styles.logo_container}>
            <img src={Logo} alt="logo" />
            Business
          </div>
          <div className={styles.welcome_container}>
            {activeTab === loginTab ? "Welcome," : "Let's start,"}
            <span>Lorem ipsum dolor sit amet</span>
          </div>
          <div className={styles.inputs_container}>
            <div className={styles.email_input}>
              <Input
                type="email"
                id="email"
                label="Your email"
                placeholder="Username@gmail.com"
              />
            </div>
            <div className={styles.password_input}>
              <Input
                type="password"
                id="password"
                label="Your password"
                placeholder="******************"
              />
            </div>
          </div>
          <div className={styles.button_container}>
            <Button
              type={1}
              text={activeTab === loginTab ? "Log In" : "Sign up"}
              onClick={() => {
                navigate(DASHBOARD);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
